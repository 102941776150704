<template>
  <gw-dialog v-model="dialog">
    <template #activator="{ on }">
      <price-viewer
        v-if="item"
        :currency-code="item.currency_code"
        :currency-id="item.currency_id"
        :tax-type-id="taxTypeId"
        :value="fPrice"
        :without-tax="!priceWithTax"
        :show-tax-label="!!fPrice"
        chip
        v-on="on"
      />
    </template>

    <template v-if="product" #title>
      <v-toolbar color="grey lighten-2" flat>
        <v-toolbar-title v-text="product.name" />
      </v-toolbar>
    </template>

    <offer-field v-if="dialog" v-model="obOffer" class="mt-4" />
  </gw-dialog>
</template>

<script lang="ts">
import { Component, Prop, VModel, Vue } from "vue-property-decorator";

import useProduct from "@/composables/product";
import GwDialog from "@/components/common/GwDialog.vue";
import OfferField from "@/components/form/fields/Offer.vue";
import PriceViewer from "@/components/common/PriceViewer.vue";
import type { ProductData } from "@planetadeleste/vue-mc-shopaholic";
import { Offer, Product } from "@planetadeleste/vue-mc-shopaholic";
import type { OfferData, ProductGwData } from "@planetadeleste/vue-mc-gw";
import { PriceList } from "@planetadeleste/vue-mc-gw";
import { get, isEmpty, isNil } from "lodash";

@Component({
  components: { GwDialog, OfferField, PriceViewer },
})
export default class PriceDialog extends Vue {
  @VModel({ type: [Object, Array], default: () => ({}) })
  offer!: OfferData | OfferData[];
  @Prop(Object) readonly product!: Product | Partial<ProductData>;

  dialog = false;
  obOffer = new Offer();
  obPriceList: PriceList | null = null;

  get item(): Partial<OfferData> {
    if (isEmpty(this.offer) && isNil(this.product)) {
      return {};
    }

    if (this.product) {
      const { obProduct } = useProduct(this.product);
      return obProduct.offer || {};
    }

    return this.offer;
  }

  get fPrice(): number | undefined {
    if (!this.item) {
      return 0;
    }

    return this.priceWithTax
      ? this.item.price_with_tax_value
      : this.item.price_value;
  }

  get priceListId(): number | undefined {
    return get(this.item, "price_list_id");
  }

  get gwProduct(): Partial<ProductGwData> {
    return this.product instanceof Product
      ? this.product.get("product_gw")
      : get(this.product, "product_gw");
  }

  get taxTypeId() {
    return this.gwProduct ? this.gwProduct.taxtype_id : undefined;
  }

  get priceWithTax() {
    return this.obPriceList ? this.obPriceList.price_with_tax : false;
  }

  async loadPriceList() {
    if (!this.priceListId) {
      return;
    }

    const obModel = new PriceList({ id: this.priceListId });
    await obModel.fetch();

    this.obPriceList = obModel;
  }

  mounted() {
    this.$nextTick(async () => {
      if (this.item) {
        this.obOffer = new Offer(this.item);
        await this.loadPriceList();
      }
    });
  }
}
</script>
